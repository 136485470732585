@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "BerlinFont";
  src: url("/src/font/berlinfont.TTF") format("truetype");
}

.font-kanit {
  font-family: "Kanit", sans-serif;
}

.font-berlin {
  font-family: "BerlinFont", sans-serif;
}

/* Hero.css */
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  background-color: #121212;
}

.font-inter {
  font-family: "Inter", sans-serif;
}

.hero-container {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  padding-top: 2rem;
}

.hero-container svg {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  width: 70%;
}

.hero-text h1 {
  margin: 0;
  padding: 0.5rem 0;
}

nav {
  width: 100%;
  top: 0;
  z-index: 40;
  transition: background-color 0.3s ease;
}

.nav-scrolled {
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
}

.hover-effect {
  transition: 0.3s ease-in-out;
}

.hover-effect:hover {
  color: rgb(255, 255, 255, 0.9);
}

.hover-effect:after {
  transition: all ease-in-out 0.2s;
  background: none repeat scroll 0 0 #0bc77f;
  content: "";
  display: block;
  height: 2px;
  width: 0;
}
.hover-effect:hover:after {
  width: 100%;
}

.navbar_container {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 0.375fr 1fr 0.375fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: grid;
}

@media screen and (max-width: 768px) {
  .navbar_container {
    display: flex;
    justify-content: center;
  }
}

.header-button {
  border: none;
  border-radius: 7px;
  transition: 0.5s;
  transition-property: box-shadow;
}

.header-button {
  background: #0bc77f;
  box-shadow: 0 0 25px #0bc77f;
}

.header-button:hover {
  box-shadow: 0 0 5px #0bc77f, 0 0 25px #0bc77f, 0 0 50px #0bc77f,
    0 0 100px #0bc77f;
}

.footer-hover {
  transition: 0.3s ease-in-out;
}

.footer-hover:hover {
  color: rgb(255, 255, 255, 0.9);
}

.button-f {
  transition: 0.4s ease-in-out;
}

.button-f:hover {
  box-shadow: 4px 6px 30px rgba(11, 199, 127, 0.5);
}

.listitm {
  border-radius: 20px;
  user-select: none;
}

.listicon {
  transition: ease-in-out 0.3s;
}

.listitm:hover {
  background-color: #f0f0f0;
  .listicon {
    color: white;
    transform: scale(1.2);
  }
  .listspan {
    transform: translate(10px, 0);
  }
}

.section4 {
  position: relative;
  overflow: hidden;
}

.section4 h1 {
  z-index: 10;
}

.section4 .absolute h1 {
  position: absolute;
  z-index: 0;
}

.button-c {
  transition: 0.4s ease-in-out;
}

.btnci {
  transition: 0.4s ease-in-out;
}

.button-c:hover {
  box-shadow: 0px 12px 30px rgba(11, 199, 127, 0.5);
  .btnci {
    transform: translateX(5px);
  }
}

.contact-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .contact-section {
    display: flex;
    flex-direction: column;
  }
}

.h-50vh {
  height: 50vh;
}
